@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
button,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
main {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  text-decoration: none;
  color: inherit;
  background: transparent;
  border: none;
}

// Variables
$black: #1d1e2b;
$lightBlack: #292a3b;
$gray: #505050;
$blue: #3d47c1;
$mediumBlue: #6888ea;
$lightBlue: #82a0fa;
$Bluelink: #2eb7ff;
$white: #fff;
$siteFont: 'Poppins', sans-serif;
$rowS: 1vw;
$rowM: 15px;
$rowL: 20px;
$rowXl: 40px;
$rowXxl: 60px;

// Slider
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: 0;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  // display: block;
  display: flex;
  align-items: stretch;
}

.slick-track:after,
.slick-track:before {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: auto;
  min-height: 1px;
  outline: none;

  >div,
  >div li,
  >div article {
    height: 100%;
    display: block !important;
  }
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

// Main site content
body {
  background-color: $black;
  font-family: $siteFont;
  color: $white;
  line-height: 1;
}

.mainWidth {
  width: 100%;
  padding-left: 6vw;
  padding-right: 6vw;
}

h1,
h2,
h3 {
  font-weight: 800;
}

h2 {
  font-size: 1.6vw;
}

p {
  font-size: 1.15vw;
}

b,
strong {
  font-weight: bold;
}

.rowS {
  margin-bottom: $rowS;
}

.rowM {
  margin-bottom: $rowM;
}

.rowL {
  margin-bottom: $rowL;
}

.rowXl {
  margin-bottom: $rowXl;
}

.rowXxl {
  margin-bottom: $rowXxl;
}

.horizontalRow {
  margin-bottom: 35px;
  // eliminar cuando se pueda agrupar
  z-index: 2;
  position: relative;
}

.flexHorizontal {
  display: flex;
  align-items: center;
  gap: 10px;

  .MuiInputBase-root.selectBase {
    margin-bottom: 0;
  }

}

// Hero
.heroContentWrap {
  position: relative;
  // when fixed
  position: fixed;
  opacity: 1;
  transition: opacity 0s linear;
  width: 100%;
  top: 0;
}

.heroHeader {
  display: flex;
  position: absolute;
  z-index: 2;
  height: 100%;
  align-items: center;

  .itemTag {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
    background-color: #EA3B33;
    border-radius: 20px;
    padding: 5px 8px;
    display: inline-flex;
    align-items: center;
    position: absolute;
    bottom: 100%;

    &::before {
      content: ' ';
      width: 7px;
      height: 7px;
      border-radius: 7px;
      background-color: white;
      display: inline-block;
      margin-right: 5px;
    }
  }

  .heroFig {
    margin-bottom: $rowS;
    height: 9vw;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: contain;
      object-position: left bottom;
      max-width: 300px;
    }
  }

  h2 {
    margin-bottom: $rowM;
    position: relative;
    z-index: 1;
  }

  button {
    margin-right: 10px;
  }
}

.heroImgBox {
  position: relative;

  .shadowLeft {
    background: linear-gradient(90deg,
        $black 10%,
        rgba($black, 0%) 100%);
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 50%;
    z-index: 1;
  }

  .shadowRight {
    background: linear-gradient(270deg,
        rgba($black, 50%) 10%,
        rgba($black, 0%) 100%);
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 10%;
    z-index: 1;
  }
}

.shadowBottom {
  background: linear-gradient(0deg,
      $black 6%,
      rgba(16, 16, 16, 0) 100%);
  position: absolute;
  bottom: 0;
  height: 50%;
  left: 0;
  right: 0;
  z-index: 1;
}

// Images
.ratioImgBox {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.ratioThumb {
    padding-bottom: 61.666%;

    .lockIcon {
      position: absolute;
      right: 5px;
      top: 5px;
      z-index: 1;
    }
  }

  &.ratioPortrait {
    padding-bottom: 133%;
  }

  &.ratioHero {
    padding-bottom: 36%;
  }

  &.ratioShortPortrait {
    padding-bottom: 115%;
  }

  &.ratioSquare {
    padding-bottom: 100%;
  }

  video,
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.newsSliderHighlighted {
  margin: 0;
  padding: 0 !important;

  .home-hero-arrow {
    width: calc(5vw + 17px) !important;

    .slick-arrow.home-hero-arrow {
      width: calc(7vw + 17px);
      height: 100%;

      .icon-border {
        cursor: pointer;
      }
    }

    .slick-list {
      width: 100%;
      margin: 0 !important;
    }

    .icon-border {
      height: 40px;
      width: 40px;
      border-radius: 20px;
      display: flex;
      box-shadow: 0 0 0 2px white, 0 0 5px rgba(0, 0, 0, 0.5);

      svg {
        margin: auto;
      }
    }

    &.slick-next .icon-border {
      margin-right: 15px;
    }

    &.slick-prev .icon-border {
      margin-left: 15px;
    }

    .slick-slide {
      .mainWidth {
        padding-left: 9vw;
        position: relative;
        margin-bottom: -3vw;
      }

      &.slick-active.slick-current .heroFig img {
        animation: showFromBottom 0.8s ease-in-out;
      }

      &.slick-active.slick-current .sponsoredLink {
        animation: showFromTop 0.8s ease-in-out;
      }
    }

    .slick-slide .sponsoredItem .mainWidth {
      padding-left: calc(8.5vw - 5px);
    }
  }
  .slick-dots {
    position: absolute;
    bottom: 0;
    z-index: 1;
    display: flex;
    left: 50%;
    transform: translateX(-50%);

    button {
      cursor: pointer;
    }

    .hero-dot {
      margin-right: 20px;

      button {
        height: 12px;
        width: 12px;
        background-color: transparent;
        border-radius: 6px;
        border: 2px solid #fff;
      }
    }

    .hero-dot-active button {
      background-color: #fff;
    }
  }

}

@keyframes showFromBottom {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes showFromTop {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

/*---------------------------------
              NAV
----------------------------------*/
.mainNavWrap {
  position: fixed;
  z-index: 21188;
  top: 0;
  width: 100%;
  left: 0;
  transition: background-color 0.8s ease;

  &.modal {

    z-index: 1299;
  }

  &.bgBlack {
    background-color: rgb(0, 0, 0, 90%);
  }

  &.fixedHeader {
    background-color: rgba(29, 30, 43, 0.8);

    .navBox {
      padding: 10px 15px;
    }

    .mainSiteLogo {
      width: 100px;
    }

  }

  .navBox {
    display: flex;
    padding: 30px 6vw 10px;
    transition: padding 0.3s ease;
  }

  .leftContent {
    display: flex;
    align-items: center;
  }

  .rightContent {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  .mainSiteLogo {
    width: 130px;
    margin-right: 10px;
    margin-bottom: 5px;

    svg {
      width: 100%;
      fill: #fff;
    }

    h1 {
      display: none;
    }
  }

  .navLink {
    padding: 10px 13px;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease;
    border-radius: 20px;
    font-size: 1em;

    &:hover {
      background-color: rgba(101, 104, 142, 0.5);
    }

    span {
      display: -ms-flexbox;
    }

    svg {
      width: 15px;
      margin-right: 5px;
      fill: #f5f5f5;
    }

    &.outlineNavLink {
      border: 2px solid white;
      border-radius: 40px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .userLink {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    overflow: hidden;
    margin-left: 10px;

    img {
      width: 100%;
      height: 100%;
      display: block;
    }

    .userAvatar {
      border-radius: 20px;
    }

    p {
      display: none;
    }
  }

  // SEARCH BAR
  .searchBar {
    position: relative;
    width: 38px;
    transition: width 0.5s ease;
    overflow: hidden;
    margin-right: 10px;

    &.focused {
      width: 300px;
    }

    >.MuiFormControl-root {
      width: 100%;
    }

    .tiggrerSearch {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
    }

    &.focused .tiggrerSearch {
      display: none;
    }

    .MuiOutlinedInput-input {
      padding: 8px 38px 11px 13px;
      z-index: 1;
      color: #fff;
      font-family: $siteFont;
      transition: opacity 0.5s ease;
      opacity: 0;
    }

    &.focused .MuiOutlinedInput-input {
      opacity: 1;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 2px solid #fff;
      border-radius: 30px;
      background-color: transparent;
      transition: background-color 0.5s ease;
    }

    &.focused .MuiOutlinedInput-notchedOutline {
      background-color: #00000070;
      border-color: #fff;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
    }

    &:hover .MuiOutlinedInput-notchedOutline {
      background-color: #00000070;
    }

    .MuiButtonBase-root.MuiIconButton-root {
      position: absolute;
      border-color: transparent;
      right: 0;
    }
  }
}

/*---------------------------------
            EVENTOS
----------------------------------*/
.app .navHorizontal {
  .MuiTab-root {
    min-width: auto;
    font-size: 1.1vw;
  }

  .MuiTabs-indicator {
    background-color: #ffffff !important;
  }
}

.eventTag {
  position: absolute;
  right: 6px;
  top: 5px;
  z-index: 1;
  background-color: $mediumBlue;
  font-weight: 600;
  padding: 6px 8px;
  border-radius: 3px;
  font-size: 0.65vw;
  box-shadow: 0 6px 10px -7px #000;
  text-transform: uppercase;
  letter-spacing: 0.04vw;

  &.free {
    right: 34px;
  }

  &[data-tagname="En vivo"] {
    background-color: #e54545;
  }

  &[data-tagname="Finalizado"] {
    background-color: gray;
  }
}

.cardEventCalendar.cardLandscape {
  li {
    border-bottom: 1px solid #4b4b4b;
    padding-bottom: 10px;
  }

  .articleItemBox {
    background-color: transparent;
    justify-content: flex-start;

    &:hover {
      transform: none;
    }

    .ratioImgBox.ratioThumb {
      width: 180px;
      padding-bottom: 7%;
      border-radius: 10px;
    }

    .cardEventDate {
      padding: 0 20px;
      width: 180px;
    }
  }
}

/*---------------------------------
          HORIZONTAL LISTS
----------------------------------*/
.articleListItem {
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  position: relative;
  height: 100%;
}

// slider horizontal
.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  margin-top: -20px;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;

  .slick-list {
    overflow: visible;
    margin-left: calc(6vw - 10px);
    // margin-right: calc(6vw - 10px);
    // Fix para mostrar siguiente slide
    margin-right: calc(-7vw - 10px);
  }

  &.newsSlider .slick-list {
    margin-right: calc(10vw - 5px);
  }

  .newsSlider h2 span {
    display: inline-block;
    vertical-align: middle;

    svg {
      display: block;
    }
  }

  .ratioImgBox {
    height: 100%;
  }

  .slick-arrow {
    position: absolute;
    left: 0;
    top: 20px;
    bottom: 20px;
    width: calc(5vw - 10px);
    // background: linear-gradient(90deg, $black 0%, #10101000 100%);
    z-index: 1;
    border: none;
    color: #fff;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    p {
      display: none;
    }

    svg {
      width: 2vw;
      height: 2.1vw;
      display: block;
      fill: #fff;
    }

    &.slick-prev {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    &.slick-next {
      // background: linear-gradient(270deg, $black 0%, #1d1d1d00 100%);
      right: 0;
      left: auto;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    &.slick-disabled {
      display: none !important;
    }
  }
}

// Placeholder
.slick-slide {
  position: relative;
}

.slick-slide:not(.slick-active)::after {
  content: ' ';
  position: absolute;
  background-color: #232433;
  left: 0.5vw;
  right: 0.5vw;
  height: 100%;
  top: 0;
  z-index: -1;
  border-radius: 15px;
  box-shadow: 0 9px 18px -5px rgba(0, 0, 0, 0.5);
}

// Listas horizontales home
.horizontalRow .slick-arrow {
  background: linear-gradient(90deg, $black 0%, #10101000 100%);
  top: -20px;
  bottom: -20px;
  padding-right: 18px;

  &.slick-next {
    background: linear-gradient(270deg, $black 0%, #1d1d1d00 100%);
    padding-left: 18px;
    padding-right: 0;
  }
}

/*---------------------------------
        ARTICLE CARDS
----------------------------------*/
// Article Boxes
.articleItemBox {
  overflow: hidden;
  border-radius: 12px;
  background-color: $lightBlack;
  transition: transform 0.3s ease;
  position: relative;
  box-shadow: 0 9px 18px -5px #0000007d;

  &:hover {
    transform: scale(1.06);
    z-index: 5;
  }

  &.whitVisibleTitle {
    header {
      padding: 16px 15px;

      p {
        margin-bottom: 4px;
        display: block;
        display: -webkit-box;
        max-width: 400px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 1.2;
      }

      h3 {
        font-size: 1.1vw;
      }
    }

    &.cwCard {
      .ratioImgBox.ratioThumb {
        padding-bottom: 73%;
      }

      header {
        padding: 36px 15px 16px;
        position: absolute;
        bottom: -1px;
        background: linear-gradient(0deg, #1c1d2b, transparent);
        width: 100%;

        p {
          font-size: 1vw;
          margin-bottom: 6px;
        }

        h3 {
          font-size: 1.05vw;
          margin-bottom: 2px;
          text-shadow: 0px 4px 3px #0003;
        }
      }
    }
  }

  &.newsArticle {
    display: flex;
    align-items: center;
    margin: 0.5vw 0 0 0.5vw;
    overflow: visible;
    height: 90px;

    .newsImgCol {
      width: 20%;
      position: relative;
      left: -10px;
      top: -1vw;
    }

    figure {
      border-radius: 6px;
      box-shadow: 7px 6px 14px #00000040;
    }

    header {
      width: 80%;
      padding: 10px;

      h3 {
        font-size: 1vw;
        font-weight: 400;
        line-height: 1.2;
      }
    }
  }

  &.withContextSerie header {
    position: absolute;
    z-index: 1;
    bottom: -1px;
    left: -1px;
    right: -1px;
    padding: 20px 10px 10px;
    backface-visibility: hidden;
    background: linear-gradient(0deg,
        #000000de 0%,
        #000000de 30%,
        #00000000 100%);
    transition: opacity 0.4s ease;
    opacity: 0;

    h3 {
      margin-bottom: $rowS;
    }

    .buttonGroup {
      display: flex;
      justify-content: space-between;
      max-width: 224px;

      .MuiButtonBase-root .MuiButton-startIcon {
        margin-right: 3px;
      }
    }
  }

  &.withContextSerie:hover header {
    opacity: 1;
  }
}

.cardLandscape {
  .articleItemBox {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ratioImgBox.ratioThumb {
    width: 35%;
    padding-bottom: 25%;
  }

  header {
    width: 75%;
  }
}

// Continuebar
.continueBar {
  background-color: rgba(156, 157, 176, 0.18);
  height: 7px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;

  span {
    height: 7px;
    display: block;
    background-color: $lightBlue;
  }
}

/*---------------------------------
              BOTONES
----------------------------------*/
//base button
.app .MuiButtonBase-root {
  font-family: $siteFont;
  font-weight: 700;
  letter-spacing: 0;
  font-size: 0.9vw;

  svg {
    width: 20px;
    height: 20px;
    display: block;
    fill: #fff;
  }

  .MuiButton-startIcon {
    margin-left: -3px;
  }

  &.MuiButton-contained {
    border-radius: 30px;
    min-height: 42px;
    background-color: #fff;
    font-size: 1vw;
    color: #2f2f2f;
    padding: 6px 24px;

    &.gray {
      background-color: #6c6c6c;
      color: #fff;
    }

    &.blue {
      background-color: $blue;
      color: #fff;
    }

    svg {
      fill: #2f2f2f;
    }

    &.medium {
      background-color: $blue;
      color: $white;
      min-height: 35px;
      font-size: 1vw;
      font-family: $siteFont;

      svg {
        fill: $white;
      }
    }
  }

  &.MuiButton-outlined,
  &.MuiIconButton-root {
    border: 2px solid #fff;
    border-radius: 20px;
    color: #fff;
    padding: 3px 9px;

    &.noRadius {
      border-radius: 6px;
    }

    &.medium {
      font-size: 1vw;
      padding: 6px 24px;
      font-family: $siteFont;
      border-radius: 40px;

      .MuiButton-label svg {
        width: 25px;
        height: 25px;
        margin-bottom: -2px;
      }
    }

    &.large {
      font-size: 1.3vw;
      font-weight: 500;
      border-radius: 40px;
      background-color: #0000003d;
      border: 4px solid white;
      text-transform: none;
      padding: 7px 35px;

      .MuiButton-label svg {
        width: 25px;
        height: 25px;
        margin-bottom: -2px;
      }

      &:hover {
        background-color: #000000b3;
      }
    }
  }

  &.MuiIconButton-root {
    border-radius: 50%;
    padding: 8px;
  }
}

// Season select
.MuiInputBase-root.selectBase {
  border: 2px solid #fff;
  color: #fff;
  font-family: $siteFont;
  border-radius: 40px;
  margin-bottom: $rowL;

  .MuiSelect-selectMenu {
    padding: 10px 45px 10px 14px;
  }

  &::after {
    display: none;
  }

  svg {
    width: 13px;
    height: 10px;
    position: absolute;
    right: 12px;
    top: 50%;
    margin-top: -4px;
    fill: #fff;
    pointer-events: none;
  }

  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgba(190, 196, 255, 0.05);
    border-width: 2px;
    background-color: rgba(190, 196, 255, 0.12);
  }
}

/*---------------------------------
                HOME
----------------------------------*/
.appWrap.fixedHero {
  padding-top: 26%;

  &.fixed-hero-container {
    padding-top: 34%;
  }
}

.homeRowsWrap {
  z-index: 2;
  position: relative;
}

// Juegos olimpicos
.featuredVideoList {
  .ratioImgBox.ratioThumb {
    padding-bottom: 0;
    height: auto;

    img {
      position: relative;
      display: block;
    }
  }
}

/*---------------------------------
              SERIE
----------------------------------*/
.grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;

  li {
    margin: 0 10px 20px;
    list-style: none;
  }

  .articleListItem {
    padding: 0;
  }

  &.grid-1-cols li {
    width: calc(100% - 20px);
  }

  &.grid-3-cols li {
    width: calc(33.333% - 20px);
  }

  &.grid-4-cols li {
    width: calc(25% - 20px);
  }

  &.grid-5-cols li {
    width: calc(20% - 20px);
  }

  &.grid-6-cols li {
    width: calc(16.666% - 20px);
  }
}

.showInfoHeader {
  display: flex;
  align-items: flex-end;

  .cardLandscape {
    width: 45%;
    margin-left: 5%;
  }

  .MuiButtonBase-root.MuiButton-contained {
    width: 100%;
    margin-top: 10px;
  }

  .MuiButtonBase-root.MuiButton-contained.bigButtonAction {
    background: linear-gradient(90deg, #3d47c1, #5762e3, #3d47c1);
    color: #fff;
    width: 44vw;
    font-size: 30px;
    border-radius: 50px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.serieData {
  width: 50%;
}

.serieReaction {
  display: flex;
  align-items: flex-start;
  margin-bottom: $rowM;
  position: relative;
  flex-direction: column;

  .containerReaction {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    width: 100%;
  }

  .serieLogo {
    margin-right: 1vw;
    max-height: 8vw;
    width: auto;
    max-width: 40%;
  }

  .buttonGroup {
    width: 50%;
    display: flex;

    .MuiButtonBase-root {
      margin-right: 10px;
    }
  }
}

.serieDescription {
  margin-bottom: $rowM;

  p {
    line-height: 1.3;
  }
}

.serieCast {
  p {
    font-size: 0.9vw;
  }

  span {
    font-weight: 300;
  }
}

.btnWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  &.withSeasons header {
    min-width: 100%;
  }

  .MuiButton-outlined {
    margin-left: auto;
  }
}

.MuiInputBase-root .MuiSelect-select {
  padding: 9px 14px;
}

.MuiButtonBase-root.MuiButton-outlined.MuiButton-sizeLarge {
  margin-left: auto;
  border-radius: 26px;
  text-transform: none;
  font-weight: 400;
  gap: 10px;
  padding: 6px 16px 5px 20px;
}

//select PopOver
.MuiPaper-root.MuiPopover-paper {
  background-color: #2e3045;
  color: #fff;
  border: 2px solid white;
  border-radius: 18px;
  margin-top: 10px;

  .MuiMenuItem-root {
    font-family: $siteFont;

    &.Mui-selected {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
}

/*---------------------------------
            CATEGORIAS
----------------------------------*/
.noHeroWrap {
  padding-top: 100px;
}

/*---------------------------------
              PLAYER
----------------------------------*/
.playerWrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/*---------------------------------
            PROFILE
----------------------------------*/
.mainWidthProfile {
  padding-left: 18vw;
  padding-right: 18vw;
  min-height: 77vh;
}

.profileSection {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.13);
}

.planSection {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.13);

  p {
    line-height: 1.4;
  }
}

.profileSection:last-child,
.planSection:last-child {
  border-bottom: 0;
}

.appVersion {
  color: #909090;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.3px;
  margin-bottom: 20px;
}

.mediumHeader {
  margin-bottom: $rowM;
  padding-bottom: 1px;

  &.centered {
    text-align: center;
  }

  h3 {
    color: $lightBlue;
    font-size: 1.5vw;
  }

  h4 {
    color: white;
    font-size: 1.2vw;
    font-weight: bold;
    margin-bottom: -8px;
  }

  hr {
    margin: 30px 0 35px;
    border: none;
    height: 1px;
    background: #8899e657;
    background: linear-gradient(90deg,
        transparent 0%,
        #8899e657 30%,
        #8899e657 70%,
        transparent 100%);
  }
}

.userAvatar {
  position: relative;

  figure {
    border-radius: 10px;
  }

  p {
    margin-top: $rowM;
    text-align: center;
  }
}

.disclaimerProfile {
  margin-top: 14px;
  background-color: #252525;
  text-align: center;
  border-radius: 3px;
  padding: 14px 18px 22px;
  line-height: 1.3;

  img {
    width: 100px;
    display: inline-block;
    margin-bottom: 7px;
  }
}

// listado de perfiles
.grid.userList {
  margin-left: -14px;
  margin-right: -14px;

  li {
    width: calc(16.666% - 36px);
    margin: 0 18px 0;
  }

  button {
    width: 100%;
  }

  .userAvatar {
    opacity: 0.4;
    transition: opacity 0.3s ease;
    cursor: pointer;

    figure {
      border: 2px solid transparent;
    }

    &.current {
      opacity: 1;

      figure {
        border-color: $white;
      }
    }

    &:hover {
      opacity: 1;
    }

    // crear usuario
    &.userCreateAvatar {
      figure {
        background-color: #0f101c;
        border-style: dashed;
        border-color: #484848;

        span {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 100%;
          position: absolute;
        }

        svg {
          fill: #fff;
        }
      }

      p {
        opacity: 0;
        transform: translateY(10px);
        transition: all 0.5s ease;
      }

      &:hover p {
        opacity: 1;
        transform: none;
      }
    }
  }
}

// Filas de texto plan
.profileTextRow {
  display: flex;
  margin-bottom: $rowL;
  flex-direction: column;

  &:last-child {
    margin-bottom: 0;
  }

  p span {
    font-weight: 300;
  }

  a {

    padding: 9px 14px;

    span span {
      display: flex;
    }
  }

  button {
    padding: 3px 14px;
    border-radius: 5px;
    text-transform: none;
    font-size: 16px !important;
    font-weight: 500 !important;

    svg {
      width: 18px !important;
      height: 15px !important;
      margin-left: 10px;
      margin-right: -10px;
      fill: #5f727b;
      stroke: #5f727b;
    }
  }

  a,
  button {
    border-radius: 5px;
    transition: background-color 0.2s ease;
    background-color: #3c3d59;
    color: #2eb7ff;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 7px;

    &:hover {
      background-color: #494a70;
    }

    svg {
      width: 18px;
      height: 15px;
      margin-left: 10px;
      margin-right: -10px;
      fill: #3696c4;
      stroke: #3696c4;
    }
  }

  .profileLinkHorizontal {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    padding-top: 20px;

    a {
      margin-bottom: 0;
    }
  }

  .profileCouponActive {
    border: 2px solid #ffffff3b;
    border-radius: 10px;
    padding: 13px 22px 12px 18px;
    display: inline-block;
    margin-top: 13px;

    span {
      font-weight: 400;
    }

    p {
      font-weight: 300;
      margin-bottom: 8px;

      &.withIcon {
        display: flex;
        align-items: center;

        span {
          font-size: 0;
        }

        svg {
          stroke: #d9d9d9;
          margin-right: 9px;
        }
      }
    }
  }

  &.cardDisplay {
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      width: 60px;
      height: auto;
      margin-right: 13px;
    }
  }
}

// Profiles full
.stagedProfilesWrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .stagedProfiles {
    width: 70%;
    text-align: center;
    margin-top: -1vw;

    header {
      margin-bottom: $rowXl;
    }

    .userList {
      margin-bottom: $rowXl;
    }
  }
}

// Profile Edit
.stagedProfileEdit .userAvatar {
  width: 200px;
  margin: 0 auto $rowXl;
  cursor: pointer;
}

.circleEdit {
  position: absolute;
  right: -12px;
  bottom: -12px;
  z-index: 1;
  background-color: #fff;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px 0 0 1px;
  border: none;
  outline: none;
  cursor: pointer;

  svg {
    width: 17px;
  }
}

.inputBase {
  label {
    margin-bottom: $rowS;
    text-align: left;
  }

  .MuiFormControl-root {
    margin-bottom: $rowM;
  }

  .MuiFilledInput-input {
    background-color: $white;
    border-radius: 6px;
    font-family: "Poppins", sans-serif;
    color: $lightBlack;
    padding: 10px;
    font-size: 20px;
  }
}

.MuiButton-contained.medium.goBack {
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}

.deleteProfile.MuiButtonBase-root.MuiButton-outlined {
  position: absolute;
  top: 13px;
  right: 13px;
  z-index: 2;
}

// Modal seleccion imagen
.closeModal {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  background-color: $blue;
  border-radius: 6px;
  z-index: 100;
  border: none;
  padding: 13px;

  svg {
    fill: #fff;
  }
}

.avatarModal .MuiBackdrop-root {
  background-color: rgb(0 0 0 / 98%);
}

.modalBox {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 60%;
  padding: 30px 30px 30px;
  border-radius: 6px;
}

.avatarList {
  margin-bottom: $rowM;

  .userAvatar {
    transition: transform 0.3s ease;
    cursor: pointer;

    &:hover {
      transform: scale(1.1);
    }

    figure {
      border: 2px solid transparent;
    }

    &.current {
      figure {
        border-color: $blue;
      }

      .circleEdit {
        right: 6px;
        bottom: 6px;
        background-color: $blue;

        svg {
          fill: $white;
        }
      }
    }
  }
}

.chooseButtons .reject {
  margin-right: 10px;
}

.chooseButtons .accept {
  background-color: $blue;
  color: $white;
}

/*---------------------------------
              FOOTER
----------------------------------*/
.footerWrap {
  background-color: #27283b;
  padding: 80px;
  position: relative;
  z-index: 20;

  svg {
    fill: #fff;
    width: 150px;
    margin: 0 auto;
    display: block;
  }

  .footerLinks {
    padding-top: 40px;
    text-align: center;

    li {
      display: inline-block;
      margin: 0 4px;
    }

    a {
      padding: 5px 10px 6px;
      border-radius: 20px;
      border: 2px solid transparent;
      transition: border-color 0.2s ease;

      &:hover {
        border-color: rgb(255 255 255 / 60%);
      }
    }
  }
}

/*---------------------------------
              PLANES
----------------------------------*/
.plansWrap {
  background: url(./assets/img/bg-web.jpg) no-repeat center;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  padding: 100px 0;

  &::after {
    content: "";
    background-color: rgba(18, 31, 88, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
  }

  .mediumHeader h3 {
    color: #fff;
    text-align: center;
    font-size: 28px;
    margin-bottom: $rowL;
  }
}

.plansColsDisplay {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: stretch;

  .planCard {
    width: 400px;
    margin: 0 10px 20px;
  }
}

.planCard {
  border-radius: 17px;
  background-color: rgba(196, 211, 255, 0.18);
  padding: 5px 5px 15px;
  border: 1px solid rgba(130, 160, 250, 0.24);
  display: flex;
  flex-direction: column;

  &:hover {
    opacity: 1;
  }

  header {
    background-color: rgb(196 211 255 / 18%);
    text-align: center;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;

    h3 {
      font-size: 23px;
      display: inline-block;
      margin-right: 5px;
      text-align: left;
    }

    img {
      max-height: 40px;
    }
  }

  ul {
    padding: 20px 0;
    margin: 0 25px;
  }

  li {
    color: #ffffff;
    margin-bottom: 15px;
    position: relative;
    display: flex;
    gap: 8px;

    &:last-child {
      margin-bottom: 0;
    }

    h5 {
      font-size: 18px;
      font-weight: bold;
      line-height: 1.4;
    }

    p {
      font-size: 17px;
      line-height: 1.4;
    }

    svg {
      max-height: 20px;
      width: 30px;
      display: block;
      margin-top: 4px;
    }

    &.disabled {
      color: #989eb8bd;

      &::before {
        background-image: url(./assets/icons/lock.svg);
      }

      svg,
      path {
        fill: #989eb8bd;
      }
    }
  }

  .MuiButtonBase-root.MuiButton-contained {
    font-size: 18px;
    color: $blue;
  }

  .planAction {
    display: flex;
    flex-direction: column;
    padding: 0 25px;
    gap: 15px;
    text-align: center;
    margin-top: auto;

    p {
      border-top: 1px solid rgba(255, 255, 255, 0.2196078431);
      padding-top: 16px;
      padding-bottom: 3px;
    }
  }

  .planPrice {
    color: #c1d0ff;
    font-size: 20px;
    text-shadow: 0 3px 2px #00000045;

    b {
      font-weight: bold;
    }

    span {
      font-size: 14px;
    }
  }

  // ACTIVE CARD
  &.active {
    opacity: 0.65;
    border-color: transparent;
    background-color: rgb(196 211 255 / 9%);

    .MuiButtonBase-root.MuiButton-contained {
      color: white;
      background-color: transparent;
      border: 2px solid #fff;

      &:hover {
        background-color: #ffffff26;
      }
    }

    .planPrice {
      display: flex;
      flex-direction: row;
      color: #2ad6a3;
      align-items: center;

      p {
        margin-left: 5px;
      }

      span {
        width: 13px;
        height: 14px;

        svg {
          fill: #2ad6a3;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.actionText {
  padding: 10px;
  text-align: center;

  p {
    font-size: 20px;
    margin-bottom: $rowL;
  }

  button {
    width: 100%;
    max-width: 330px;
  }
}

.modalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;

  .modalWrapPlans {
    background-color: white;
    position: relative;
    border-radius: 10px;
    padding: 50px 50px 50px 30px;
    max-width: 600px;
    width: 90vw;
    text-align: center;
    outline: none;
  }

  p {
    color: $gray;
    margin-bottom: 25px;
    line-height: 1.3;

    b {
      font-weight: 800;
    }
  }

  button:last-child {
    margin-left: 10px;
  }
}

.medium.plansUserMail {
  position: absolute;
  right: 10px;
  top: 9px;
}

/*---------------------------------
        REGISTER SCREEN
----------------------------------*/
.registerScreenWrap {
  background: url(./assets/img/bg-web.jpg) no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  display: flex;

  .logo {
    fill: #fff;
    margin-bottom: 20px;
    width: 180px;
    margin-top: -10px;

    @media (max-width: 800px) {
      width: 120px;
    }
  }

  div {
    text-align: center;
    max-width: 800px;
  }

  p {
    line-height: 1.5;

    @media (max-width: 800px) {
      br {
        display: none;
      }
    }
  }

  b {
    font-weight: 800;
    color: $lightBlue;
  }

  .registerScreenButtonWrap {
    padding-top: 20px;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

/*---------------------------------
            Prehome
----------------------------------*/
.preHomeContainer {
  background: linear-gradient(24deg, #17194D, #333567, #505193);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .preHomeBox {
    padding: 40px 60px;
    background: linear-gradient(45deg, rgba(144, 178, 255, 0.15), rgba(255, 255, 255, 0.18), rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.06));
    border-radius: 20px;
    box-shadow: 0 20px 30px -30px #15163c;
    max-width: 800px;
    position: relative;
  }

  h2 {
    margin-bottom: 20px;
    line-height: 1.15;
  }

  .preHomeHeader {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 10px;

    svg {
      fill: #FFF;
      width: 70px;
      height: auto;
    }

    p {
      color: #E55858;
      display: flex;
      align-items: center;
      padding-left: 10px;
      border-left: 1px solid #8688aa;
      font-size: 17px;

      &::before {
        content: " ";
        width: 8px;
        height: 8px;
        background-color: #E55858;
        border-radius: 50%;
        display: block;
        margin-right: 6px;
        margin-left: 2px;
      }
    }
  }

  .buttonsWrap {
    .dark.MuiButtonBase-root.MuiButton-contained {
      background-color: #6162aa;
      color: white;
    }

    button.MuiButtonBase-root.MuiButton-contained {
      margin-right: 10px;
      color: #5A5B91;

      svg {
        fill: #5A5B91;
      }
    }
  }

  .countDown {
    top: 100%;
    position: absolute;
    right: 5px;
    font-size: 17px;
    padding-top: 12px;
    color: #cbcbe2;
  }
}

/*---------------------------------
      Neutral message screen 
----------------------------------*/
.loadingWrap {
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1111;
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}

/*---------------------------------
          MEDIAQUERY -1200px 
----------------------------------*/
@media only screen and (max-width: 1200px) {

  // Hero home
  .appWrap.fixedHero {
    padding-top: 40%;
  }

  .ratioImgBox.ratioHero {
    padding-bottom: 40%;
  }

  .mediumHeader h3 {
    font-size: 23px;
  }

  .mediumHeader h4 {
    font-size: 18px;
  }

  p {
    font-size: 16px;
  }

  .app {
    .MuiButtonBase-root.MuiButton-contained.medium {
      font-size: 16px;
    }

    .MuiButtonBase-root.MuiButton-contained {
      font-size: 18px;
    }

    .MuiButtonBase-root {
      font-size: 14px;
    }
  }

  h2 {
    font-size: 1.6em;
  }

  h3,
  .articleItemBox.whitVisibleTitle header h3 {
    font-size: 1em;
  }

  .articleItemBox.withContextSerie header .buttonGroup .MuiButtonBase-root.MuiButton-outlined {
    min-width: auto;

    .MuiButton-label {
      text-indent: -9999px;

      .MuiButton-startIcon {
        margin-right: 0;
      }
    }
  }

  // Plans
  .planCard li {
    font-size: 1em;
  }
}

/*---------------------------------
          MEDIAQUERY -1024px 
----------------------------------*/
@media only screen and (max-width: 1024px) {

  // Listas Shows
  .grid.grid-3-cols li {
    width: calc(50% - 10px);
  }

  // Categories
  .grid.grid-5-cols li {
    width: calc(25% - 10px);
  }

  // Botones
  .MuiButton-contained.medium.goBack {
    top: 5px;
    left: 5px;
    background-color: transparent !important;
    box-shadow: none;
    padding-left: 12px;
  }

  .MuiButton-contained.medium.plansUserMail {
    top: 5px;
    right: 5px;
  }

  // Plans
  .plansColsDisplay .planCard {
    max-width: 45%;
  }
}

/*---------------------------------
          MEDIAQUERY -800px 
----------------------------------*/
@media only screen and (max-width: 800px) {

  // Nav
  .mainNavWrap .navLink.outlineNavLink {
    text-indent: -9999px;
    width: 41px;
    justify-content: center;

    svg {
      display: block;
      margin-right: 0;
      width: 18px;
      height: 14px;
    }
  }

  // Hero home
  .appWrap.fixedHero {
    padding-top: calc(65% - 100px);
  }

  .ratioImgBox.ratioHero {
    padding-bottom: 65%;
  }

  .plansSelection .row p {
    width: 60vw;
  }

  // Card show
  .articleItemBox.withContextSerie header .buttonGroup {
    transform: scale(77%);
    transform-origin: bottom left;
    gap: 3px;
  }

  .showInfoHeader {
    justify-content: flex-start;
    flex-direction: column;

    .serieData {
      width: 100%;
    }

    .cardLandscape {
      width: 100%;
    }
  }

  // Profile
  .mainWidthProfile {
    padding-left: 3%;
    padding-right: 3%;
  }

  .grid.userList li {
    width: calc(16.666% - 20px);
    margin: 0 10px 0;
  }

  .stagedProfilesWrap .stagedProfiles {
    width: 89%;
  }

  // Categories
  .grid.grid-5-cols li {
    width: calc(33.333% - 10px);
  }

  //Plans
  .plansWrap .mediumHeader h3 {
    font-size: 24px;
    margin-bottom: 0;
  }

  // Modal
  .modalContainer {
    .MuiBackdrop-root {
      background-color: rgba(0, 0, 0, 0.8);
      backdrop-filter: blur(2px);
    }

    .modalWrapPlans {
      padding: 80px 15px 25px;
    }

    p {
      color: $gray;
      margin-bottom: 25px;
      line-height: 1.3;

      b {
        font-weight: 800;
      }
    }

    .MuiButtonBase-root.MuiButton-contained {
      width: 100%;
      max-width: 270px;
      margin-left: 0;
      margin-bottom: 15px;
    }
  }

  // Video
  .app .controlsWrapper .title {
    padding-top: 15px;
  }

  // Profile
  .profileTextRow {
    display: block;

    a {
      width: 100%;
      padding: 20px 0;
      margin: 10px 0 0;
      color: #fff;
      text-decoration: none;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.5px;
      justify-content: center;

      svg {
        fill: #fff;
        stroke: #fff;
      }
    }

    button {
      width: 100%;
      background-color: #3c3d59;
      padding: 7px 5px;
      margin: 10px 0 0;
      color: #fff;
      text-decoration: none;
      text-align: center;
      font-size: 18px !important;
      border-radius: 6px;
      font-weight: 600 !important;
      letter-spacing: 0.5px;

      svg {
        fill: #fff;
        stroke: #fff;
      }
    }

    .profileLinkHorizontal {
      flex-direction: column;
    }
  }

  .app .mainWidthProfile .MuiButtonBase-root.MuiButton-contained {
    width: 100%;
  }
}

/*---------------------------------
          MEDIAQUERY -600px 
----------------------------------*/
@media only screen and (max-width: 600px) {

  // Nav
  .mainNavWrap .navLink {
    padding: 10px 5px;

    svg {
      display: none;
    }
  }

  .mainNavWrap .leftContent .mainSiteLogo+.navLink {
    display: none;
  }

  // Hero home
  .appWrap.fixedHero {
    padding-top: calc(80% - 100px);
  }

  .ratioImgBox.ratioHero {
    padding-bottom: 80%;
  }

  .plansSelection .row p {
    width: 60vw;
  }

  // Listas Shows
  .grid.grid-3-cols li {
    width: calc(100% - 10px);
  }

  // Plans
  .plansWrap {
    background: url(./assets/img/bg-mobile.jpg) no-repeat center top;
    background-size: cover;
    background-attachment: fixed;
  }

  .plansColsDisplay .planCard {
    max-width: 100%;
    width: 100%;
  }
}

/*------------------------------
          CONTACT MESSAGE
---------------------------------*/
.bgDark {
  background-image: url("./assets/img/bg-web.jpg");
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactMessage {
  text-align: center;

  .megagologo svg {
    fill: white;
    width: 150px;
    margin-bottom: 80px;
    margin-top: -40px;
  }

  h2 {
    margin-bottom: 15px;
  }

  p {
    margin-bottom: 20px;
    line-height: 1.5;

    b {
      font-weight: 600;
      letter-spacing: 0.3px;
    }
  }

  button {
    width: 400px;
    margin: 0 auto 20px;
    display: flex;

    &.MuiButtonBase-root.MuiButton-contained[value="WhatsApp"] .MuiButton-startIcon svg {
      fill: #25d366;
    }

    &.MuiButtonBase-root.MuiButton-contained[value="Mail"] .MuiButton-startIcon svg {
      stroke: #1c42f1;
    }

    &.MuiButtonBase-root .MuiButton-startIcon {
      margin-left: auto;
      margin-right: 10px;
    }

    .MuiButton-endIcon {
      margin-left: auto;
      margin-top: -2px;

      svg {
        width: 17px;
        height: 17px;
        stroke: #6f6f6f;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .contactMessage {
    width: 100%;
    padding: 0 20px;

    button {
      width: 100%;
    }
  }
}

/*------------------------------
          NOTIFICACIONES
---------------------------------*/
.paperWrap .MuiDrawer-paper {
  background-color: $black;
}

.MuiButtonBase-root.MuiIconButton-root.closeDrawer {
  position: absolute;
  right: 1.6vw;
  border: none;
  top: 1.4vw;
}

// globo
.notifyPop {
  position: relative;

  .notifyNumber {
    background-color: red;
    width: 20px;
    height: 20px;
    font-size: 11px;
    text-align: center;
    border-radius: 50%;
    position: absolute;
    top: -5px;
    right: -7px;
    z-index: 10;
    padding-top: 4px;
    box-shadow: -2px 4px 7px #00000063;
  }
}

.MuiDrawer-root.MuiDrawer-modal {
  z-index: 30000;
}

.notificationsWrap {
  padding: 2vw;
  padding-bottom: 5vw;
  min-width: 25vw;

  h2 {
    color: #fff;
    margin-bottom: $rowL;
  }

  .notifyItem {
    border-bottom: 1px solid rgba(255, 255, 255, 0.158);
    margin-bottom: $rowS;
    padding-bottom: $rowS;

    a {
      display: flex;
      flex-grow: column;
      color: #fff;
      align-items: center;
    }

    .leftContent {
      margin-right: 1vw;
      position: relative;
      width: 70px;

      .alertBallon {
        width: 10px;
        height: 10px;
        background-color: red;
        position: absolute;
        top: -3px;
        left: -5px;
        border-radius: 50%;
        z-index: 1;
        box-shadow: -2px 4px 7px #00000063;
      }
    }

    .centerContent {
      padding-right: 1vw;
      width: 250px;
    }

    p {
      margin-bottom: 0.4vw;
    }

    h5 {
      font-weight: bold;
      margin-bottom: 0.3vw;
    }

    span {
      font-size: 12px;
    }

    img {
      border-radius: 5px;
    }

    .rightContent {
      width: 150px;
    }
  }
}

/*----------------------
          PLAYER
--------------------------*/
.app {
  .screenPlay {
    position: absolute;
    width: 100%;
    top: 15%;
    bottom: 130px;
    left: 0;
    z-index: 4;
    cursor: pointer;
  }

  .playerWrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    background-color: #000;

    #mdstrm {
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }

    #mdstrm iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

  .controlsWrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 4;
    transition: opacity 0.6s ease;
    background-color: #00000033;

    &.hide {
      opacity: 0;
      // Fix publicity
      // pointer-events: none;
    }

    .topControls {
      padding: 30px 70px 10%;
      background: rgb(16, 16, 16);
      background: linear-gradient(0deg,
          rgba(16, 16, 16, 0) 0%,
          rgba(16, 16, 16, 1) 100%);
    }

    .bottomControls {
      padding: 10% 75px 30px;
      gap: 15px;
      background: rgb(16, 16, 16);
      background: linear-gradient(0deg,
          rgba(16, 16, 16, 1) 0%,
          rgba(16, 16, 16, 0) 100%);
    }

    .title {
      font-size: 20px;

      span {
        font-weight: normal;
      }
    }

    .controlIcons {
      opacity: 0.5;
      transform: scale(0.9);
      transition: transform 0.2s ease;
      border: none;

      &:hover {
        transform: scale(1);
      }

      svg {
        width: 100px;
        height: 100px;
      }
    }

    .timeSlider {
      color: #404dda;
      height: 8px;

      .MuiSlider-rail {
        height: 8px;
        background-color: #f0f8ff;
        border-radius: 8px;
      }

      .MuiSlider-track {
        height: 8px;
        border-radius: 8px;
      }

      .MuiSlider-thumb {
        height: 22px;
        width: 22px;
        // margin-top: -7px;
        // margin-left: -11px;
        box-shadow: -1px 2px 5px #0000004d;
        background-color: #4956e6;
        transition: box-shadow 0.2s ease;

        &:hover {
          box-shadow: 0px 0px 0px 8px rgba(173, 173, 247, 16%),
            -1px 2px 8px #0000004d;
        }

        &.MuiSlider-active {
          box-shadow: 0px 0px 0px 14px rgba(173, 173, 247, 16%),
            -1px 2px 8px #0000004d;
        }
      }
    }

    .bottomIcons {
      color: #fff;
      border: none;
      width: 50px;
      height: 50px;

      svg {
        width: 36px;
        height: 36px;
      }

      &:hover {
        color: white;
      }
    }

    .playerIcon {
      margin-left: 22px;

      svg {
        width: 100px;
        fill: #fff;
      }
    }

    .MuiSlider-root.volumeSlider {
      width: 100px;
      margin-right: 20px;
      margin-left: 10px;
      color: $lightBlue;
      height: 6px;

      .MuiSlider-rail {
        height: 6px;
        background-color: #f0f8ff;
        border-radius: 6px;
      }

      .MuiSlider-track {
        height: 6px;
        border-radius: 8px;
      }

      .MuiSlider-thumb {
        height: 16px;
        width: 16px;
        // margin-top: -5px;
        // margin-left: -8px;
        box-shadow: -1px 2px 5px #0000004d;
        background-color: #94afff;
        transition: box-shadow 0.2s ease;

        &:hover {
          box-shadow: 0px 0px 0px 8px rgba(173, 173, 247, 16%),
            -1px 2px 8px #0000004d;
        }

        &.MuiSlider-active {
          box-shadow: 0px 0px 0px 14px rgba(173, 173, 247, 16%),
            -1px 2px 8px #0000004d;
        }
      }
    }
  }

  .nextEpisodeFullScreen {
    background-color: #000000cf;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    left: 0;

    p {
      text-align: center;
      margin-bottom: 16px;
      width: 63%;
      margin-left: 35%;
    }

    .interaction {
      position: absolute;
      right: 4%;
      bottom: 10%;

      .buttons button {
        margin-left: 15px;
      }
    }
  }
}

.popSpeed .MuiPaper-root.MuiPopover-paper {
  background-color: rgb(29, 27, 27);

  .MuiButton-root {
    color: #fff;
  }
}

.playerWatherMark {
  display: flex;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 111;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  height: 100%;

  &.hide {
    display: none;
  }

  .markWrap {
    position: relative;
    aspect-ratio: 16/9;
    width: calc(100vh * 1.78);
    // background-color: #ff000066;
    max-height: 100vh;

    .markIcon {
      position: absolute;
      top: 12px;
      right: 10px;

      svg {
        width: 164px;
        height: 54px;
        fill: rgba(255, 255, 255, 0.3);
        filter: drop-shadow(-3px 3px 6px rgba(0, 0, 0, 0.1));
      }
    }
  }
}

//error 429
.error429-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  // padding-top: 13px;
  background-color: rgba(240, 240, 240, 0.8);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  background: #ffffff14 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;

  span>svg.injected-svg {
    fill: #fff;
    width: 150px;
    margin: 0 auto;
    position: absolute;
    top: -100px;
    left: 0;
    right: 0;
  }

  >div {
    display: flex;
    align-items: center;

    h1 {
      color: var(--unnamed-color-ffffff);
      text-align: left;
      font: normal normal bold 26px/57px Poppins;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
      line-height: normal;
      margin-bottom: 10px;
    }

    p {
      color: var(--unnamed-color-ffffff);
      text-align: left;
      font: normal normal normal 20px/22px Poppins;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }

    .MuiCircularProgress-root {
      margin-right: 30px;
    }
  }
}

/*----------------------
  section devolutions pending
--------------------------*/
.divModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 99;
  bottom: 20px;
  left: 50%;
  width: 598px;
  height: 63px;
  background: #3142AD;
  color: #FFFFFF;
  border-radius: 9px;
  opacity: 1;
  font: normal normal 16px / 19px Poppins;
  letter-spacing: 0px;
  cursor: pointer;
  transform: translateX(-50%);
  box-shadow: 0 7px 14px #00000073;
  transition: background-color 0.3s ease;
}
.divModal:hover{
  background-color: #4f61d3;
}
.gridmodal {
  align-items: center;
  display: flex;
}

.iconPending {
  margin-right: 10px;
  width: 30px !important;
  height: 30px !important;
}

.styleBox {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 546px;
  height: 642px;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 10px;
  padding: 32px;
  color: black;
  opacity: 1;
  padding-top: 40px;
  &:focus-visible{
    outline: none;
  }
}

.textTitleTypography {
  text-align: center;
  font: normal normal bold 20px/30px Poppins !important;
  letter-spacing: 0px;
  color: #4A4B7C;
  opacity: 1;
  margin-bottom: 12px;
}

.textDescription {
  text-align: center;
  font: normal normal 16px/23px Poppins;
  letter-spacing: 0.02px;
  color: #4A4B7C !important;
  opacity: 1;

  strong {
    text-align: center;
    font: normal normal bold 16px/20px Poppins;
    letter-spacing: 0.02px;
    color: #4A4B7C !important;
  }
}

.gridinfomodal {
  background: #FAFAFA 0% 0% no-repeat padding-box;
  border: 1px solid #E3E3E3;
  border-radius: 9px;
  opacity: 1;
  padding: 35px;
  margin-top: 15px;
  text-align: -webkit-center;

  p {
    text-align: center;
    font: normal normal 15px/20px Poppins;
    letter-spacing: 0.02px;
    color: #5F5F5F;
    opacity: 1;
  }

  strong {
    text-align: center;
    font: normal normal bold 16px/20px Poppins;
    letter-spacing: 0.02px;
    color: #5F5F5F;
  }

  Button {
    margin-top: 20px;
    box-shadow: 0 4px 11px 0px #cecece;
  }
}

.btncupon {
  background: #7B99F0 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 4px 8px #0000001A;
  border-radius: 6px;
  opacity: 1;
  min-height: 45px;
  padding: 0 20px !important;

  p {
    text-align: -webkit-center;
    font: normal normal 16px / 20px Poppins;
    letter-spacing: 0.07px;
    color: #FFFFFF;
    opacity: 1;
    text-transform: none;
  }
}

.btncompensation {
  height: 45px;
  background: #4A4B7C 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 4px 8px #0000001A;
  border-radius: 6px;
  opacity: 1;
  padding: 0 20px !important;

  p {
    text-align: -webkit-center;
    font: normal normal 16px / 20px Poppins;
    letter-spacing: 0.07px;
    color: #FFFFFF;
    opacity: 1;
    text-transform: none;
  }
}

.gridContentdialog {
  padding-top: 40px;
  margin-bottom: 10px;
}

.gridContentdialogfinalizado {
  padding: 20px;
  width: 493px;
}

.IconButtonX {
  position: absolute !important;
  right: 0px;
  top: 0px;
  color: rgba(0, 0, 0, 0.54) !important;
}

.textTitleTypographyconfirm {
  text-align: center;
  color: #4A4B7C;
  font-weight: bold !important;
  font: normal normal bold 20px/30px Poppins;
  letter-spacing: 0px;
  opacity: 1;
}

.textDescriptiondevolution {
  text-align: center;
  font: normal normal 17px/20px Poppins;
  letter-spacing: 0.07px !important;
  color: #5F5F5F !important;
  opacity: 1;
}

.btnCancel {
  background: #909090 0% 0% no-repeat padding-box !important;
  width: 256px;
  height: 45px;
  box-shadow: 0px 4px 8px #0000001A !important;
  border-radius: 6px !important;
  opacity: 1;

  p {
    text-align: center;
    font: normal normal 17px / 20px Poppins !important;
    letter-spacing: 0.07px !important;
    color: #FFFFFF !important;
    opacity: 1;
    text-transform: none;
  }
}

.btnAccept {
  width: 256px;
  height: 45px;
  background: #4A4B7C 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 4px 8px #0000001A !important;
  border-radius: 6px !important;
  opacity: 1;

  p {
    text-align: center;
    font: normal normal 17px / 20px Poppins !important;
    letter-spacing: 0.07px !important;
    color: #FFFFFF !important;
    text-transform: none;
  }
}

.dialogAccept {
  text-align: center;
}

.textDescriptionacept {
  text-align: center;
  font: normal normal 16px / 23px Poppins;
  letter-spacing: 0.02px !important;
  color: #4A4B7C !important;
  opacity: 1;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.gridinfo {
  padding: 35px;
  text-align: -webkit-center;
  background: #1C1C1C 0% 0% no-repeat padding-box;
  border: 1px solid #292929;
  border-radius: 13px;
  top: 210px;
  color: var(--unnamed-color-ffffff);
}

.btnDevolutionAndCupon {
  background-color: #4A4B7C !important;
  width: 366px;
  height: 45px;
  top: 17px;
}

.btnDevolutionCupon {
  background: #6769AF 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 4px 8px #0000001A;
  border-radius: 23px;
  opacity: 1;
  width: 335px;
  height: 20px;
  top: 17px;

  p {
    text-align: center;
    font: normal normal 17px/20px Poppins !important;
    letter-spacing: 0.07px;
    color: #FFFFFF;
    opacity: 1;
    text-transform: none !important;
  }
}

.btnDevolution {
  background: #4A4B7C 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 4px 8px #0000001A;
  border-radius: 23px;
  opacity: 1;
  width: 366px;
  height: 45px;
  top: 17px;

  p {
    text-align: center;
    font: normal normal 17px/20px Poppins !important;
    letter-spacing: 0.07px;
    color: #FFFFFF;
    opacity: 1;
  }
}

.dialogActions {
  justify-content: center !important;
}

strong {
  font-weight: 600;
  color: #FFFFFF;
}

.gridrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: 25px;
  position: relative;
}

@media only screen and (max-width: 600px) {
  .divModal {
    width: 90%;
    padding-left: 15px;
    padding-right: 15px;
  }

  .contentDialogSmall .MuiDialog-paper{
    margin: 0 3%;
  }

  .styleBox {
    width: 96%;
    height: auto;
    overflow: auto;
    padding: 20px 15px;
    max-height: 100vh;
  }

  .btncupon {
    width: 100%;
  }

  .textTitleTypographyconfirm {
    font: normal normal bold 19px / 30px Poppins;
  }

  .textDescriptiondevolution {
    font: normal normal 15px / 17px Poppins;
  }

  .gridContentdialogfinalizado {
    width: initial;
  }
  
  .gridinfomodal{
    padding: 20px 15px;
  }
}

//Estilos para patrocinados
.appWrap.fixedHero.alone-item-container {
  padding-top: 40%;

  .ratioImgBox.ratioHero {
    padding-bottom: calc(40% - 10px);
  }
}

.sponsoredItem {
  padding: 40px 20px 0;

  &.sponsoredShowItem {
    padding-top: 20px;

    >.shadowBottom {
      background: linear-gradient(0deg, #1d1e2b 40px, rgba(16, 16, 16, 0) 100%);
      bottom: -40px;
    }
  }

  .itemContainer,
  .heroImgBox {
    overflow: hidden;
    border-radius: 30px 30px 0 0;
    position: relative;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.42), 0 0 5px 2px rgb(0, 0, 0, 0.19);
  }
}

.slick-current .sponsoredItem {
  animation: showPatrocinate 0.5s ease-in-out
}

@keyframes showPatrocinate {
  0% {
    padding: 0;
  }

  100% {
    padding: 40px 20px 0;
  }
}

.sponsoredLink {
  padding: 10px 14px;
  background: rgba(255, 255, 255, 0.85);
  color: #323260;
  display: flex;
  flex-direction: row;
  margin-top: 14px;
  border-radius: 10px;
  align-items: center;
  font-size: 14px;
  justify-content: center;
  transition: transform 0.2s ease;
  margin-right: 10px;

  &:hover {
    transform: scale(1.05)
  }

  img {
    margin-left: 10px;
    max-height: 35px;
  }

  button {
    cursor: pointer;
    display: flex;
    align-items: center;

    &:focus-visible {
      outline: none;
    }
  }
}

.sponsoredLinkWrap {
  position: absolute;
  bottom: 100%;
  margin-bottom: 10px;

  .sponsoredLink {
    margin-right: 0;
    padding: 14px 31px;
    width: fit-content;
  }
}

.newsSliderHighlighted .slick-dots {
  bottom: 50px;
}

#showAdButton {
  position: fixed;
  background-color: black;
  padding: 40px;
  z-index: 100000;
  top: 0;
}