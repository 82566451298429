@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');
/*----------------------------
          ONBOARDING
----------------------------*/
.app .onboardingContainer {
  font-family: 'Poppins', sans-serif;
  .mobileHidden{
    display: block !important;
  }
  .mobileVisible{
    display: none !important;
  }
  .fullHSection{
    height: 100vh;
    position: relative;
  }
  .obMainWidth{
    width: 1000px;
    margin: 0 auto;
  }
  // Nav
  .nav{
    position: fixed;
    z-index: 10;
    display: flex;
    flex-direction: row;
    top: 0;
    width: 100%;
    padding: 10px 15px 18px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    .navLogo svg{
      fill: white;
      width: 90px;
    }
    .right{
      margin-left: auto;
      display: flex;
      align-items: center;
      gap: 10px;
      > span{
        width: 1px;
        height: 30px;
        background-color: rgba(255, 255, 255, 0.5);
        display: block;
      }
    }
  }
  // Hero
  .heroSection{
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    header{
      text-align: center;
      margin-top: auto;
      padding-top: 20px;
      .heroLogo svg{
        fill: white;
        width: 200px;
        margin-bottom: 14px;
      }
      h2{
        font-size: 42px;
        margin-bottom: 5px;
      }
      // span momentáneo de plan anual
      .planAnual{
        font-size: 2.2rem;
        font-weight: 300;
      }
      // fin span momentáneo de plan anual
      p{
        margin-bottom: 20px;
      }
      .disclaimer{
        padding-top: 8px;
        display: block;
        font-size: 12px;
        opacity: 0.6;
        font-style: italic;
        span{
          line-height: 15px;
        };
      }
    }
    .MuiButtonBase-root.MuiButton-outlined.moreContentButton{
      padding: 15px 20px;
      font-size: 14px;
      border: none;
      background: none;
      margin-bottom: 15px;
      background-color: rgba(0, 0, 0, 0.3);
      border: 2px solid rgba(255, 255, 255, 0.6);
      &:hover{
        background-color: rgba(0, 0, 0, 0.7);
      }
      svg{
        margin-left: 0;
      }
    }
  }
  // Buttons
  .moreContentButton{
    margin-top: auto;
    margin-bottom: 20px;
    font-size: 16px;
  }
  .MuiButtonBase-root.MuiButton-contained,
  .MuiButtonBase-root.MuiButton-outlined{
    font-family: 'Poppins', sans-serif;
    border-radius: 30px;
    background: linear-gradient(91deg,#1A4CBA, #232191);
    color: white;
    text-transform: none;
    padding: 10px 30px;
    font-weight: 400;
    font-size: 18px;
    line-height: 1;
    &.yellow{
      background: linear-gradient(190deg,#ffbe4c,#c97c00);
      color: #3f4080;
      svg{
        fill: #3f4080;
        height: 15px;
      }
    }
    &.introButton{
      font-size: 22px;
      padding: 18px 50px;
      font-weight: bold;
    }
    svg{
      fill: white;
      margin-left: 15px;
      width: 8px;
      height: 12px;
    }
  }
  .MuiButtonBase-root.MuiButton-outlined{
    padding: 11px 22px;
    font-size: 16px;
    background: rgba(0, 0, 0, 0.2);
    border-color: rgba(255, 255, 255, 0.6);
    &:hover{
      border-color: rgba(255, 255, 255, 1);
    }
  }
  // Section common
  .sectionListWrap{
    .sectionBg{
      background: linear-gradient(196deg, #526ff1, #0e114a);
      position: fixed;
      top: 0;
      width: 100%;
      height: 100%;
      left: 0;
    }
  }
  .sectionRow{
    position: relative;
    z-index: 1;
    padding: 80px 10px;
  }
  .sectionHeader{
    text-align: center;
    margin-bottom: 30px;
    span{
      display: block;
      color: #84a3ee;
      margin-bottom: 5px;
      font-size: 20px;
    }
  }
  .cardPromo{
    background-color: #52FED5;
    color: black;
    border-radius: 3px;
    padding: 8px;
    margin-bottom: 10px;
    position: absolute;
    top: 0;
    font-size: 14px;
    transform: translateY(-87%);
    width: 100%;
    left: 0;
  }
  // Plan comparison
  .tableContainer{
    border-radius: 15px;
    background-color: rgba(14, 14, 35, 0.2);
    box-shadow: 0 24px 30px -13px rgba(0, 0, 0, 0.4);
    padding: 5px 20px 5px;
    table{
      width: 100%;
      // margin-bottom: -80px;
      tr{
        border-bottom: 1px solid rgba(0, 0, 0, 0.4);
        td{
          padding: 14px 10px;
          font-size: 16px;
          &:nth-child(n+2){
            text-align: center;
            position: relative;
          }
          b{
            color: #52ffd5;
          }
        }
        .iconInclude{
          fill: #52ffd5;
        }
        .iconExclude{
          fill: #ca2d2b;
          svg{
            width: 11px;
            height: 20px;
            stroke: #ca2d2b;
          }
        }
        .planPrice{
          font-size: 18px;
          font-weight: bold;
          p{
            font-weight: normal;
            font-size: 17px;
            display: block;
            padding-top: 6px;
            span{
              font-size: 11px;
            }
          }
        }
      }
      tbody tr:last-child{
        border-bottom: none;
      }
    }
  }
  .tableBnr{
    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: center;
    border-top: 1px solid #ffffff0f;
    border-right: 1px solid #ffffff0f;
    > span svg{
      width: 150px;
      margin-right: 15px;
    }
    > p{
      // font-size: 16px;
      // font-weight: 600;
      // border-left: 1px solid rgba(255, 255, 255, 0.2);
      margin-right: 15px;
      font-weight: 300;
      font-size: 16px;
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      img.vtr{
        width: 90px;
        height: auto;
        margin-right: -4px;
        margin-left: -3px;
      }
      img.claro{
        width: 86px;
        height: auto;
        margin-top: -10px;
      }
      a{
        background-color: rgba(0, 0, 0, 0.1);
        color: white;
        display: inline-block;
        padding: 7px 11px;
        border-radius: 18px;
        margin: 0 1px;
        text-shadow: none;
        border: 2px solid rgba(255, 255, 255, 0.6);
        transition: border-color 0.2s ease;
        &:hover{
          border-color: white;
        }
      }
    }
    div{
      display: flex;
      align-items: center;
      // margin-left: auto;
      p{
        font-size: 12px;
        margin-right: 4px;
      }
      img{
        width: 100px;
        margin-left: 5px;
      }
    }
  }
  // Video background fixed
  .videoBg{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(37deg, #171935, #1b1a39);
    -webkit-mask-image: linear-gradient(#000000 50%, rgba(16, 16, 16 , 0) 100%);
    mask-image: linear-gradient(black, transparent);
    overflow: hidden;
    video, iframe{
      box-sizing: border-box;
      height: 56.25vw;
      left: 50%;
      min-height: 100%;
      min-width: 100%;
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      width: 177.77777778vh;
      opacity: 0.6;
    }
  }
  .videoBgNoBorder{
    video, iframe{
      height: 97.25vw;
      width: 242.777778vh;
    }
  }
  @media only screen and ( max-width: 1000px){
    .videoBgNoBorder{
      video, iframe{
        height: 300.25vw;
        width: 311.777778vh;
      }
    }
  }
  // Card
  .programList{
    display: flex;
    gap: 15px;
    li{
      width: 25%;
    }
    .programCard{
      height: 0;
      padding-bottom: 130%;
      position: relative;
      transition: all 0.3s ease;
      &:hover{
        transform: scale(1.05);
      }
      .card{
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: cover;
        position: absolute;
        box-shadow: 0 20px 30px -10px #00000078;
      }
      .icon{
        position: absolute;
        max-width: 80%;
        bottom: 0;
        z-index: 1;
        top: 100%;
        transform: translateY(-50%) translateX(-50%);
        max-height: 93px;
        left: 50%;
        filter: drop-shadow(0px -7px 9px rgba(0, 0, 0, 0.5));
      }
    }
    .eventCard{
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0 20px 30px -10px #00000078;
      transition: all 0.3s ease;
      background-color: #3e3571;
      height: 100%;
      &:hover{
        transform: scale(1.05);
      }
      img{
        width: 100%;
      }
      > div{
        text-align: center;
        background: linear-gradient(0deg, #3E3471 20%, rgba(62, 52, 113, 0) 100%);
        margin-top: -210px;
        position: relative;
        padding: 140px 10px 25px;
        background-size: 100px 215px;
        background-position: top;
        min-height: 250px;
        &.eventSports{
          background-position: 0 90px;
          background-repeat: no-repeat;
          background-size: 100% 50%;
        }
        h4{
          color: #FFF;
          font-size: 18px;
          margin-top: -35px;
          font-weight: bold;
          margin-bottom: 11px;
          line-height: 1.15;
        }
        p{
          color: #FFF;
          font-size: 14px;
          line-height: 1.2;
        }
      }
    }
  }
  // Dispositivos
  .devicesComposition{
    display: flex;
    align-items: center;
    > img{
      max-width: 59%;
      margin-left: -2%;
    }
    header{
      text-align: left;
      padding-left: 10px;
    }
    .osList{
      display: flex;
      align-items: center;
      gap: 5px;
      padding-top: 10px;
      img{
        height: 30px;
      }
    }
  }
  // Accordion
  .MuiAccordion-rounded{
    background-color: rgba(14, 14, 35, 0.2);
    transition: background-color 0.4s ease;
    box-shadow: none;
    &:first-child {
      border-top-left-radius: 14px;
      border-top-right-radius: 14px;
    }
    &:last-child {
      border-bottom-left-radius: 14px;
      border-bottom-right-radius: 14px;
    }
    &.MuiAccordion-root.Mui-expanded{
      margin: 0;
    }
    &.Mui-expanded{
      background-color: rgba(14, 14, 35, 0.33);
    }
    p{
      font-size: 16px;
      color: rgba(255, 255, 255, 0.7);
      line-height: 1.4;
      .link{
        color: #aaf3ff;
        text-decoration: underline;
      }
    }
  }
  .MuiAccordionSummary-root{
    font-family: 'Poppins', sans-serif;
    h3{
      font-size: 18px;
      color: white;
      font-weight: normal;
    }
    .MuiButtonBase-root.MuiIconButton-root{
      border: none;
    }
  }
}
@media only screen and ( max-width: 1000px){
  .app .onboardingContainer {
    // nav
    .nav{
      display: none;
    }
    .mobileHidden{
      display: none !important;
    }
    .mobileVisible{
      display: block !important;
    }
    .obMainWidth{
      width: 100%;
    }
    .videoBg iframe{
      opacity: 0.3;
    }
    .cardPromo{
      transform: none;
      font-size: 10px;
      min-width: 110px;
      top: -4px;
    }
    .heroSection{
      height: 90vh;
      header{
        padding: 10px 35px;
        h2{
          font-size: 27px;
        }
        // span momentáneo de plan anual
        p{
          font-size: 13px;
        }
        .planAnual{
          font-size: 1.5rem;
          font-weight: 300;
        }
        // fin span momentáneo de plan anual
        button{
          font-size: 16px !important;
          width: 100% !important;
          padding: 17px 20px !important;
        }
        .disclaimer{
          margin-top: 5px;
          margin-bottom: 10px;
          font-size: 14px;
          br {
            display: none;
          }
          span{
            line-height: 20px;
          }
          &:first-child{
            font-style: initial;
          }
        }
        .MuiButtonBase-root.MuiButton-outlined{
          margin-bottom: 13px;
        }
        .mobileLoginButtons .disclaimer:last-child{
          margin-top: -8px;
          font-size: 12px;
        }
      }
      .mobileLoginButtons{
        border-top: 1px solid rgba(255, 255, 255, 0.22);
        margin-top: 20px;
      }
    } 
    // Table
    .tableContainer{
        padding: 0;
        text-align: center;
        table {
        position: relative;
        display: block;
        thead, tbody{
          display: block;
        }
        tr{
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          min-width: 100%;
          justify-content: space-around;
          padding: 5px 10px;
          td:first-child{
            width: 100%;
            padding-bottom: 0px;
          }
          .planPrice p{
            display: block;
            font-size: 16px;
          }
          // footer
          &:last-child{
            flex-wrap: nowrap;
            align-items: stretch;
            gap: 10px;
            padding: 10px;
            td{
              padding: 0;
              flex-shrink: 1;
              width: 100%;
              justify-content: flex-start;
              display: flex;
              flex-direction: column;
              .MuiButtonBase-root.MuiButton-outlined{
                width: 100%;
                height: 100%;
              }
              &:first-child{
                display: none;
              }
            }
          }
        }
        &.showAnualPlan .planPrice{
          padding-top: 30px !important;
        }
        .planPrice br{
          display: none;
        }
        tbody tr:last-child td:last-child{
          display: none;
        }
        thead{
          position: sticky;
          top: 0;
          background-color: #313a73;
          z-index: 1;
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
          tr{
            padding: 5px 0;
          }
          td:first-child{
            display: none;
          }
        }
      }
    }
    .tableBnr{
      flex-direction: column;
      padding: 15px 15px 20px;
      span svg{
        width: 120px;
        height: 40px;
        margin-right: 10px;
        margin-bottom: 10px;
      }
      > p{
        margin-bottom: 14px;
        flex-direction: column;
        img.claro{
          margin-bottom: 5px;
        }
      }
      div{
        margin-left: 0;
      }
    }
    .programList {
      gap: 10px;
      flex-direction: row;
      flex-wrap: wrap;
      li {
        flex: 42%;
        margin-bottom: 44px;
      }
      .programCard .icon{
        max-height: 90px;
      }
    }
    // Devices
    .devicesComposition{
      flex-direction: column;
      > img{
        width: 86%;
        margin-left: 0;
        max-width: 400px;
      }
      header{
        text-align: center;
      }
      .osList{
        padding-top: 20px;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    // Accordion
    .MuiAccordionSummary-root{
      h3 {
        font-size: 16px;
        line-height: 1.2;
        text-align: center;
      }
    }
    .MuiAccordion-rounded p{
      font-size: 14px;
      text-align: center;
      line-height: 1.2;
    }
  }
  .footerWrap{
    padding-left: 0;
    padding-right: 0;
    .footerLinks li{
      margin: 0 1px
    }
    .footerLinks a{
      display: block;
    }
  }
}
@media only screen and ( max-width: 350px){
  .app .onboardingContainer {
    .programList.eventList li{
      flex: 100% 1;
      margin-bottom: 20px;
    }
  }
}
// Colors
.app .onboardingContainer{
  &.pink{
    .videoBg{
      background: linear-gradient(37deg, #e16993, #2b0d17);
    }
    .sectionListWrap .sectionBg {
      background: linear-gradient(196deg, #bb7380, #a14977);
    }
    .sectionHeader span{
      color: #ffaec6;
    }
    .tableContainer table tr{
      border-bottom-color: rgb(214, 145, 163);
      .iconExclude{
        fill: #dd2a60;
        svg{
          stroke: #dd2a60;
        }
      }
    }
    .tableBnr > p a{
      color: #ffaec6;
    }
    .eventCard{
      background-color: #992d4d;
      > div{
        background: linear-gradient(0deg, #992d4d 20%, #992d4d00 100%);
      }
    }
    @media only screen and ( max-width: 1000px){
      .tableContainer table thead{
        background-color: #7c1c3f;
      }
    }
  }
  &.skyblue{
    .videoBg{
      background: linear-gradient(37deg, #1f5fa9, #0f3d72);
    }
    .sectionListWrap .sectionBg {
      background: linear-gradient(196deg, #5b9aee, #1f5fa9);
    }
    .sectionHeader span{
      color: #3bdd08;
    }
    .tableContainer table tr{
      border-bottom-color: rgba(161, 193, 221, 0.38);
      .iconExclude{
        fill: #dd2a60;
        svg{
          stroke: #dd2a60;
        }
      }
    }
    .tableBnr > p a{
      color: #3bdd08;
    }
    .eventCard{
      background-color: #11325d;
      > div{
        background: linear-gradient(0deg, #11325d 20%, #00000000 100%);
      }
    }
    @media only screen and ( max-width: 1000px){
      .tableContainer table thead{
        background-color: #144c95;
      }
    }
  }
  &.skybluePurple{
    .videoBg{
      background: linear-gradient(37deg, #1f5fa9, #0f3d72);
    }
    .sectionListWrap .sectionBg {
      background: linear-gradient(196deg, #b562f9, #531fcd);
    }
    .sectionHeader span{
      color: #4fd9fd;
    }
    .heroSection header .disclaimer{
      opacity: 0.9;
    }
    .tableContainer table tr{
      border-bottom-color: rgba(161, 193, 221, 0.38);
      .iconExclude{
        fill: #f67b0a;
        svg{
          stroke: #f67b0a;
        }
      }
    }
    .tableBnr > p a{
      color: #4fd9fd;
    }
    .eventCard{
      background-color: #381180;
      > div{
        background: linear-gradient(0deg, #381180 20%, #00000000 100%);
      }
    }
    @media only screen and ( max-width: 1000px){
      .tableContainer table thead{
        background-color: #4e1b8c;
      }
    }
  }
  &.darkblue{
    .videoBg{
      background: linear-gradient(37deg, #1e1b2f, #0a071e);
    }
    .sectionListWrap .sectionBg {
      background: linear-gradient(196deg, #3e385f, #1e1b2f);
    }
    .sectionHeader span{
      color: #dd2a60;
    }
    .tableContainer table tr{
      border-bottom-color: rgb(55, 50, 85);
      .iconExclude{
        fill: #dd2a60;
        svg{
          stroke: #dd2a60;
        }
      }
    }
    .tableBnr > p a{
      color: #dd2a60;
    }
    @media only screen and ( max-width: 1000px){
      .tableContainer table thead{
        background-color: #231f3e;
      }
    }
  }
  &.baseBlue{
    .videoBg{
      background: linear-gradient(37deg, #1e1b2f, #0a071e);
    }
    .sectionListWrap .sectionBg {
      background: linear-gradient(196deg, #3e385f, #1e1b2f);
    }
    // .sectionHeader span{
      // color: #dd2a60;
    // }
    .tableContainer table tr{
      border-bottom-color: rgb(55, 50, 85);
      // .iconExclude{
      //   fill: #dd2a60;
      //   svg{
      //     stroke: #dd2a60;
      //   }
      // }
    }
    .tableBnr > p a{
      color: #dd2a60;
    }
    @media only screen and ( max-width: 1000px){
      .tableContainer table thead{
        background-color: #231f3e;
      }
    }
  }
  &.darkFire{
    .videoBg{
      background: linear-gradient(37deg, #1e1b2f, #0a071e);
    }
    .sectionListWrap .sectionBg {
      background: linear-gradient(196deg, #633333, #17161e);
    }
    .sectionHeader span{
      color: #cc4b4b;
    }
    .tableContainer {
      background-color: rgba(57, 57, 91, 0.2);
    }
    .tableContainer table tr{
      border-bottom-color: rgba(87, 64, 64, 0.38);
      .iconExclude{
        fill: #cc4b4b;
        svg{
          stroke: #cc4b4b;
        }
      }
    }
    .tableBnr > p a{
      color: #cc4b4b;
    }
    .eventCard{
      background-color: #672931;
      > div {
        background: linear-gradient(0deg, #672a31 20%, rgba(62, 52, 113, 0) 100%);
      }
    }
    @media only screen and ( max-width: 1000px){
      .tableContainer table thead{
        background-color: #3c2d4e;
      }
    }
  }
  .tableContainer table tr td:last-child{
    display: none;
  }
  .tableContainer table.showAnualPlan tr td:last-child{
    display: block;
  }
  .cardDisclaimer{
    position: absolute;
    top: 110%;
    text-align: center;
    font-size: 12px;
    color: #fb80dd;
    width: 100%;
    padding: 8px;
    top: calc(100% + 45px);
    line-height: 1.1;
  }
  // Fixed promo advice
  .promoCode{
    position: fixed;
    z-index: 19;
    text-align: center;
    padding: 20px 40px 21px 27px;
    left: 0px;
    display: flex;
    align-items: center;
    gap: 20px;
    box-shadow: rgb(0 0 0 / 26%) 0px -7px 40px;
    width: 100%;
    justify-content: center;
    bottom: 0px;
    background: linear-gradient(90deg, transparent, rgb(101, 81, 219) 25%, rgb(101, 81, 219) 75%, transparent);
    img{
      width: 157px;
      height: auto;
      margin-top: -5px;
    }
    h2{
      font-size: 21px;
      margin-bottom: 5px;
    }
    p{
      font-size: 17px;
      line-height: 1.13;
      b{
        color: #3cf9ff;
      }
    }
  }
  @media only screen and ( max-width: 1000px){
    .promoCode{
      flex-direction: column;
      background: rgb(101, 81, 219);
      padding: 14px 10px 20px;
      gap: 10px;
      img{
        width: 130px;
      }
      h2{
        font-size: 17px;
        margin-bottom: 3px;
      }
      p{
        font-size: 14px;
        line-height: 1.2;
      }
    }
  }
}